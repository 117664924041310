import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import { BsFillEyeFill } from "react-icons/bs"
import SEO from "../components/SEO"

const ProjectTemplate = ({ data }) => {
  const {
    title,
    subtitle,
    mainText: { mainText },
    coverImage,
    linkToFile
  } = data.contentfulProject
  const pathToImage = getImage(coverImage)
  return (
    <Layout>
      <SEO title={title} mainText={mainText} />
      <main className="page">
        <div className="recipe-page">
          {/* hero */}
          <section className="recipe-hero">
            <GatsbyImage
              image={pathToImage}
              alt={title}
              className="about-img"
            />
            <article className="recipe-info">
              <h2>{title}</h2>
              <h3 style={{ color: "#70747c" }}>{subtitle}</h3>
              <a href={linkToFile}>
                <button type="submit" className="btn with-icon">
                  <div>
                    <BsFillEyeFill />
                  </div>
                  <div>View case study</div>
                </button>
              </a>
            </article>
          </section>
          {/* rest of content */}
          {/* <section className="recipe-content">
            <article>
              <h4>description</h4>
              <p>{mainText}</p>
            </article>
            <article className="second-column">
              <div>
                <h4>ingredients</h4>
              </div>
              <div>
                <h4>tools</h4>
              </div>
            </article>
          </section> */}
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query getSingleProject($title: String) {
    contentfulProject(title: { eq: $title }) {
      title
      subtitle
      mainText {
        mainText
      }
      coverImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
      }
      linkToFile
    }
  }
`

export default ProjectTemplate
